.carMetaForm {
  width: 300px;
  height: 460px;
  display: flex;
  border: solid 1px #e9edf4;
  background-color: #ffffff;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 30px 20px;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #869ab7;
  }

  .body {
    width: 100%;
    height: 379px;
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;

    .brands, .modelGroups {
      display: flex;
      flex-direction: column;
    }

    .brands {
      padding-left: 15px;
      box-sizing: border-box;
      padding-right: 10px;
      padding-bottom: 20px;
    }

    .modelGroups {
      min-height: 340px;
      padding-left: 40px;
      padding-top: 10px;
      box-sizing: border-box;
      padding-right: 10px;
      background-color: #f9fbfd;
      bottom: 0;
      width: 100%;

      .selectedModelGroup {
        padding-bottom: 10px;
      }

      .selectedModel {
        padding-bottom: 10px;
      }

      .models {
        padding-left: 20px;
      }

      .grades {
        padding-left: 40px;
      }
    }
  }

  .selectedBrand {
    padding-right: 10px;
    display: flex;
    padding-left: 20px;
    width: 100%;
    border-bottom: solid 1px #e9edf4;
    box-sizing: border-box;
    height: 40px;
    padding-bottom: 10px;
  }
  .spinnerWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
