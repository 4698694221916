@import "../../../styles/variables";

.button {
  color: $dark;
  position: relative;
  transition: background 0.3s, box-shadow 0.3s;
  font-size: 12px;
  &:hover {
    box-shadow: 1px 1px 7px rgba(33, 33, 33, 0.3);
  }
}

.secondary {
  padding: 2px 8px;
  background-color: white;
  font-size: 12px;
  border-radius: 2px;
  &:hover {
    background-color: $light-gray;
  }
}
.thirdly {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 4px 10px;
  font-size: 12px;
  border-radius: 13px;
  &:hover {
    background-color: $light-gray;
  }
}

.primary {
  border-radius: 2px;
  background-color: $blue;
  color: white;
  padding: 8px 12px;
}

.text {
  border-radius: 2px;
  border: solid 1px $blue;
  color: $blue;
  padding: 8px 12px;
}

.disabled {
  background-color: $gray2;
  cursor: auto !important;
  &:hover {
    background-color: $gray2;
    box-shadow: none;
  }
}
