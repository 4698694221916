@import "../../../styles/variables";

.switch {
  display: flex;
  height: 16px;
  align-items: center;

  .label {
    font-size: 12px;
    line-height: 1.67;
    color: #869ab7;
    margin-right: 8px;
  }

  .body {
    width: 35px;
    cursor: pointer;
    height: 16px;
    background-color: #ccd8e8;
    border-radius: 20px;
    position: relative;
    transition: 0.15s background-color ease-in-out;

    .shape {
      width: 20px;
      height: 20px;
      box-shadow: 0 1px 4px 0 rgba(65, 77, 107, 0.3);
      background-color: #f7f8fb;
      position: absolute;
      border-radius: 50%;
      left: 0;
      top: -2px;
      transition: 0.15s transform ease-in-out;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        font-size: 12px;
        transition: 0.15s color ease-in-out;
      }
    }
  }

  &.isActivated {
    .body {
      background-color: $blue;

      .shape {
        transform: translateX(16px);

        .icon {
          color: $blue;
        }
      }
    }
  }
}
