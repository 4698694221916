@import '../../../styles/variables';

.carMetaItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding: 5px;
  width: 100%;
  box-sizing: border-box;

  .radio {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
    border: solid 1px #dce2eb;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 50%;
    margin-right: 10px;
  }

  .label {
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }

  .count {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: $blue;
    margin-left: 10px;
  }

  &.isSelected {
    .radio {
      background: url(../../../images/radio-check-mark-checked.svg) no-repeat center;
      border: none;
    }
  }

  &.isDisabled {
    .label, .count {
      color: $hidden;
    }
  }

  &.isModel {
    cursor: auto;
  }
}
