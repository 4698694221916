.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #efefef;

  .form {
    width: 300px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
    background: #fff;
    border-radius: 4px;

    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: url(../../images/teamwork.gif) no-repeat center;
      background-size: 60%;
      margin-bottom: 10px;
      height: 100px;
    }

    .input {
      width: 100%;
      margin-bottom: 10px;
    }

    .actions {
      display: flex;
      justify-content: flex-end;

      .submit {
        width: 100%;
        border-radius: 4px;
        color: #fff;
        border: none;
        height: 42px;
        background: #396eff;
        position: relative;
        padding: 10px;
        font-size: 14px;
        box-shadow: 0 3px 6px rgba(103, 157, 255, 0.2);
        transition: box-shadow 0.3s ease-in-out;

        &:hover {
          box-shadow: 0 6px 12px rgba(103, 157, 255, 0.4);
        }

        &.isLoading {
          opacity: 0.5;
        }
      }
    }
  }
}
