.inputField {
  display: flex;
  overflow: hidden;
  padding-left: 5px;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 8px;
  background: #fafafa;
  transition: background .3s ease-in-out, box-shadow .3s ease-in-out;

  &.isFocus {
    background: #efefef;
  }

  .iconWrapper {
    color: #396eff;
    width: 32px;
    height: 38px;
    position: relative;

    svg {
      position: absolute;
      width: 20px;
      height: 20px;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
  }

  .input {
    border: none;
    padding: 5px;
    background: none;
    width: calc(100% - 32px);
    height: 38px;
    box-sizing: border-box;
    outline: none;
    font-size: 14px;
  }
}
