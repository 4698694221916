@font-face {
  font-family: "SDPRNDGothicNeoaUni-fSb";
  font-weight: normal;
  font-style: normal;
  src: url("../font/SDPRNDGothicNeoaUni-fSb.woff");
}

@font-face {
  font-family: "SDPRNDGothicNeoaUni-dRg";
  font-weight: normal;
  font-style: normal;
  src: url("../font/SDPRNDGothicNeoaUni-dRg.woff");
}

@font-face {
  font-family: "SDPRNDGothicNeoaUni-cLt";
  font-weight: normal;
  font-style: normal;
  src: url("../font/SDPRNDGothicNeoaUni-cLt.woff");
}

body {
  font-size: 14px;
  margin: 0;
  font-family: "SDPRNDGothicNeoaUni-dRg", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #272e40;
}


button {
  border: none;
  background: none;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
}
