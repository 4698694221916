@import '../../../styles/variables';

.checkbox {
  width: 100%;
  display: flex;

  .wrapper {
    display: flex;
    cursor: pointer;
  }

  .input {
    display: none;
  }

  .shape {
    &.isChecked {

    }
  }

  .shape {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border: solid #afc2db 1px;
    background: #ffffff;
    box-sizing: border-box;

    &.isChecked {
      background: $blue;
      border: none;
    }
  }

  .label {
    text-align: left;
    font-size: 12px;
    line-height: 16px;
    margin-left: 4px;
  }
}
