$white: #ffffff;
$sky: #00b0ff;
$red: #ec6878;
$redLight: #ffb4b4;
$yellow: #ffbd5a;
$green: #50e3c2;
$blue: #396eff;
$gray: #9ba2ac;
$hidden: #869ab7;
$disable: #c8d1d9;
$dark: #272e40;
$light-gray: #e9edf4;
$purple: #ba7bf1;
$deepBlue: #425363;
$hidden: #98a3ad;
$orange: #ffbd5a;

$gray0: #fbfcff;
$gray1: #f7f8fb;
$gray2: #e9edf4;
$gray3: #8599b8;
$gray4: #869ab7;
$gray5: #ccd8e8;

$hd_navy_8: #272e40;
$hd_navy_5: #afc2db;
$hd_navy_4: #ccd8e8;
$hd_navy_3: #e9edf4;
